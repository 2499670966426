import type { Session } from '@auth/core/types'

type User = Session['user']

export function useAuthUser() {
  const { data } = useAuth()

  return computed<User>(() => (data.value?.user || {
    name: '',
    image: '',
  }) as User)
}
